<template>
  <div>
    <form v-on:submit.prevent>
      <div class="imgcontainer">
        <img src="../assets/HeaderLogo.png" alt="Logo" class="avatar" />
      </div>

      <h2>Reset Password</h2>

      <div class="container">
        <label for="uname"><b>Email</b></label>
        <input type="text" placeholder="Enter Email" name="Email" required v-model="email" />
        <p v-if="!emailField" class="invalid-email">Please enter a valid email !</p>
        <button type="submit" @click="getNewPassword()">Reset Password</button>
      </div>
    </form>
  </div>
</template>

<script>
import PFServices from "../services/PFServices.js";

export default {
  name: "login-page",
  data() {
    return {
      email: "",
      emailField: true
    };
  },
  methods: {
    getNewPassword() {
      if (this.email.length > 0) {
        PFServices.getNewPassword(this.email).then((res) => {
          if (res) {
            this.$router.push("Info");
          }
        });
      } else {
        this.emailField = false
      }
    },
  },
};
</script>

<style scoped >
form {
  border: 3px solid #f1f1f1;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.invalid-email {
  color: #FF0000;
}

button:hover {
  opacity: 0.8;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
}
</style>