<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="$emit('close-error-message')">
          x
        </button>
        <br />
        <slot>Please fill in required fields</slot>
      </header>
      <div>
        <button type="button" class="btn-red" @click="$emit('close-error-message')">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-messasge",
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #FF0000;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-red {
  float: left;
  background-color: #FF0000;
  border: none;
  color: #ffffff;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  margin: 4px 2px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 16px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
  background: transparent;
}

.btn-blue {
  float: right;
  background-color: #0000ff ;
  border: none;
  color: #ffffff;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  margin: 4px 2px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 16px;
}
</style>