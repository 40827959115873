<template>
  <div>
    <h1>
      {{selectedClient.Forename}} {{ selectedClient.Surname.charAt(0) }} - {{ selectedClient.ClientRef }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'header-component',
  props: ['selectedClient']
}
</script>

<style scoped>
</style>