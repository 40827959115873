<template>
  <!-- <div id="nav"> -->

    
    <!-- <router-link to="/">Home</router-link> | -->
    <!-- <router-link to="/about">About</router-link> -->
  <!-- </div> -->
  <router-view/>
</template>

<script>


export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      authorisation: false,
    }
  },
}
</script>

<style >
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://kit.fontawesome.com/f04b25585e.js');

*{
  overflow-x:none
}

#app,*{
  overflow-y:none
}

*{
  padding:0;
  margin:0;
}

#app {
  /* font-family:'open sans'; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header-bar {
  z-index: 1;
  border-bottom: 1px solid  black;
  padding-bottom: 10px;
  height: auto;
}

.side-bar {
  height: 100%;
  width: 17vw;
  position: absolute;
  z-index: 2;
  left: 0;
  background-color: #dad4fa;
  overflow-x: hidden;
  padding-top: 20px;
}
</style>
