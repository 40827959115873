<template>
  <div class="home">
    <HeaderSupervisor class="header-bar" :user="user.Forename" />
    <SideBarSupervisor class="side-bar" v-on:view-client="viewClient($event)" :currentClients="currentClients" :previousClients="previousClients" />
  </div>
</template>

<script>
import SideBarSupervisor from "../components/SideBarSupervisor.vue";
import HeaderSupervisor from "../components/HeaderSupervisor.vue";
import PFServices from  "../services/PFServices.js"
// import { Console } from "console";

export default {
  name: 'home',
  components: {
    SideBarSupervisor,
    HeaderSupervisor
  },
  data() {
    return {      
      user: JSON.parse(localStorage.getItem('User')),
      previousClients: [],
      currentClients: [],
      selectedClient: null,
    }
  },
  methods: {
    getClients() {
      this.previousClients = [];
      this.currentClients = [];
      PFServices.getClientsList(this.user.SupervisorID) 
      .then(res => {
        for (let client of res.GetClientListResult) {
          if (client.CurrentClient === 1) {
            this.currentClients.push(client)
          } else { 
            this.previousClients.push(client)
          }
        }
        localStorage.setItem('CurrentClients', JSON.stringify(this.currentClients));
        localStorage.setItem('previousSessions', JSON.stringify(this.previousClients));
      })  
    }, 
    viewClient(client) {
      localStorage.setItem('CurrentClient', JSON.stringify(client));
      this.selectedClient = client
      if (client.CurrentClient === 1) {
        // localStorage.setItem('CurrentClient', JSON.stringify(client));
        this.$router.push("CurrentClient");
      } else {
        // localStorage.setItem('PreviousClient', JSON.stringify(client));
        this.$router.push("PreviousClient");
      }
    },
    redirectToHome() {
      if (this.user === undefined || this.user === null) {
        console.log('Redirect to Home')
      }
    }
  },
  mounted() {
    this.redirectToHome()
//    this.getClients()    
    console.log('Mounted')
  }
}
</script>

<style scoped>

</style>
