<template>
  <div>
    <ModalPopup v-if="isModalVisible" v-on:close-popup="closeModal" v-on:confirm-and-send="setNextSession" />
    <ModalFailurePopup v-if="isModalFailureVisible" v-on:close-failure-popup="CloseFailurePopup" />
    <ErrorMessage v-if="isErrorMessageVisible" v-on:close-error-message="closeErrorMessage" />
    <SessionErrorMessage v-if="isSessionErrorMessageVisible" v-on:close-session-error-message="closeSessionErrorMessage" />
    <h2 class="client-name">
      {{ selectedClient.Forename }} {{ selectedClient.Surname.charAt(0) }} - {{ selectedClient.ClientRef }}
    </h2>

    <h3 class="title">This Week's Session</h3>

    <form v-on:submit.prevent>
      <table class="client-form">
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Attendance</th>
          <th>Schedule Next Session</th>
          <th>Mode of Next Session</th>
          <th>Notes For PF Staff</th>
        </tr>

        <tr>
          <td>{{ getFormatedDate }}</td>
          <td>{{ getFormatedTime }}</td>

          <td>
            <select v-model="formData.AttendanceID" class="attendance">
              <option value=null>Select</option>
              <option v-for="attendanceOption of attendanceOptions" :key="attendanceOption.iTypeID" v-bind:value="attendanceOption.iTypeID" >
                {{ attendanceOption.sTypeDescription }}
              </option>
            </select>
          </td>

          <td class="calendar-table">
            <div class="radio-input">
              <input type="radio" v-model="formData.SessionDate" value="7" id="7" required />
              <label name="SessionDate" for="html" class="radio-button" >1 Week</label>

              <br />

              <input type="radio" v-model="formData.SessionDate" value="14" id="14" />
              <label name="SessionDate" for="html" class="radio-button" >2 Weeks</label>

              <br />

              <input type="radio" v-model="formData.SessionDate" value=""  id="0" />
              <label name="SessionDate" for="html" class="radio-button"
              >Final Session</label>

              <br />
              <label for="SessionDate">Select a Date:</label>
              <br />

              <div class="date-next-session">
                <input class="calendar" type="date" name="SessionDate" v-model="formData.SessionDate" />

                <br />

                <select v-model="hours">
                  <option value="null">Hour</option>
                  <option v-for="time of times" :key="time.hour" v-bind:value="time.hour" >
                    {{ time.hour }}
                  </option>
                </select>

                <select v-model="minutes">
                  <option value="null">Minutes</option>
                  <option value="00">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                </select>
              </div>
            </div>
          </td>

          <td>
            <select v-model="formData.ModeID" class="mode">
              <option value=0>Select</option>
              <option v-for="modeOption of modeOptions" :key="modeOption.iTypeID" v-bind:value="modeOption.iTypeID" >
                {{ modeOption.sTypeDescription }}
              </option>
            </select>
          </td>

          <td>            
            <textarea class="notes" v-model="formData.Notes" type="text" name="Notes" id="Notes" maxlength="100" placeholder="Max 100 characters; BACS and card donations are imported automatically" />
          </td>
        </tr>
      </table>

      <div class="submit-button">
        <button id="submitbutton" type="submit" @click="showModal()">Submit</button>
      </div>
      <div class="submit-button">
        <button id="testbutton" type="submit" @click="runTests()">Display Diagnostics</button>
      </div>      
    </form>
  </div>
</template>

<script>
import PFServices from "../services/PFServices.js";
import ModalPopup from "../components/ModalPopup.vue";
import ModalFailurePopup from "../components/ModalFailurePopup.vue";
import ErrorMessage from "../components/ErrorMessage.vue";
import SessionErrorMessage from "../components/SessionErrorMessage.vue";

export default {
  name: "client-form",
  components: {
    ModalPopup,
    ModalFailurePopup,
    ErrorMessage,
    SessionErrorMessage,
  },
  props: ["selectedClient", "currentSessions"],
  data() {
    return {
      isModalVisible: false,
      isModalFailureVisible: false,
      isErrorMessageVisible: false,
      isSessionErrorMessageVisible: false,
      hours: null,
      minutes: null,
      formData: {
        OrganisatinID: 31,
        AppToken: 123456,
        SessionID: "",
        SessionDate: null,
        AttendanceID: null,
        Notes: "",
        ModeID: null,
        SessionTime: null,
      },
      attendanceOptions: [],
      modeOptions: [],
      times: [
        { hour: "05" },
        { hour: "06" },
        { hour: "07" },
        { hour: "08" },
        { hour: "09" },
        { hour: "10" },
        { hour: "11" },
        { hour: "12" },
        { hour: "13" },
        { hour: "14" },
        { hour: "15" },
        { hour: "16" },
        { hour: "17" },
        { hour: "18" },
        { hour: "19" },
        { hour: "20" },
      ],
    };
  },
  methods: {
    setNextSession() {
      console.log('Before Fill:', this.formData.SessionID)
      this.formData.SessionID = this.currentSessions.SessionID
      console.log('After Fill:', this.formData.SessionID);
      this.isModalVisible = false;
      PFServices.postSession(this.formData)
      .then(res => {
        if (res.SetSessionDetailsResult.WasSuccessful !== "OK") {
          console.log('Error: ', res.SetSessionDetailsResult)
          var message = `Error: ${res.SetSessionDetailsResult.WasSuccessful}`
          alert(message);
        } else {
          //console.log("test 1")
          location.reload()
        }
      })
      this.formData.AttendanceID = 0
      this.formData.ModeID = 0
      this.formData.Notes = ''
      this.formData.SessionDate = null
      this.formData.SessionTime = null
      //console.log("Test")

      this.isModalVisible = false;
      alert("Press Ok to Refresh Screen");
      location.reload();
    },

    CloseFailurePopup() {
      console.log('Before Fill:', this.formData.SessionID)
      this.formData.SessionID = this.currentSessions.SessionID
      console.log('After Fill:', this.formData.SessionID);
      this.isModalFailureVisible = false
    },

    cleanUp(){
      this.formData.Notes = ''
    },

    runTests() {
      if (this.currentSessions) {
        this.formData.SessionID = this.currentSessions.SessionID
        this.formData.SessionID = this.currentSessions.SessionID
        if (this.formData.SessionDate === "14" || this.formData.SessionDate === "7" || this.formData.SessionDate === "") { // Is one of the three radio buttons for schedule next session ticked
          this.formData.SessionTime = this.currentSessions.SessionTimeISO;
          let formatedDate = new Date(this.currentSessions.SessionDateISO);
          if (this.formData.SessionDate === "14") { // 2 Weeks for next session
            formatedDate.setDate(formatedDate.getDate() + 14);
            const MyDateString = `${formatedDate.getFullYear()}-${("0" +(formatedDate.getMonth() + 1)).slice(-2)}-${("0" + formatedDate.getDate()).slice(-2)}`;
            this.formData.SessionDate = MyDateString;
          } else if (this.formData.SessionDate === "7") { // 1 Week for next session
            formatedDate.setDate(formatedDate.getDate() + 7);
            const MyDateString = `${formatedDate.getFullYear()}-${("0" +(formatedDate.getMonth() + 1)).slice(-2)}-${("0" + formatedDate.getDate()).slice(-2)}`;
            this.formData.SessionDate = MyDateString;
          } else  { // Final Session
            this.formData.SessionDate = ""
          }
        } else { // Otherwise uses the Date/Time Picker
          if (this.hours == null) {
            this.hours = this.currentSessions.SessionTimeISO.split(":")[0];
          }
          if (this.minutes == null) {
            this.minutes = this.currentSessions.SessionTimeISO.split(":")[1];
          }
          this.formData.SessionTime = `${this.hours}:${this.minutes}:00`;
        }
        let formatedDate = new Date(this.currentSessions.SessionDateISO);
        const dTodaysDate = new Date()
      
        console.log(">>> Session Information")
        console.log("SessionID: ", this.formData.SessionID)
        console.log("New SessionDate: ", this.formData.SessionDate)
        console.log("New SessionTime: ", this.formData.SessionTime)
        console.log("Formatted Date: ", formatedDate)
        console.log("Today's Date: ", dTodaysDate)
        console.log("AttendanceID: ", this.formData.AttendanceID)
        console.log("ModeID: ", this.formData.ModeID)
      } else {
      console.log("Error - No Current Sessions")
    }
      },

    showModal() {
      if (this.currentSessions) {
        this.formData.SessionID = this.currentSessions.SessionID
        if (this.formData.SessionDate === "14" || this.formData.SessionDate === "7" || this.formData.SessionDate === "") { // Is one of the three radio buttons for schedule next session ticked
          this.formData.SessionTime = this.currentSessions.SessionTimeISO;
          let formatedDate = new Date(this.currentSessions.SessionDateISO);
          if (this.formData.SessionDate === "14") { // 2 Weeks for next session
            formatedDate.setDate(formatedDate.getDate() + 14);
            const MyDateString = `${formatedDate.getFullYear()}-${("0" +(formatedDate.getMonth() + 1)).slice(-2)}-${("0" + formatedDate.getDate()).slice(-2)}`;
            this.formData.SessionDate = MyDateString;
          } else if (this.formData.SessionDate === "7") { // 1 Week for next session
            formatedDate.setDate(formatedDate.getDate() + 7);
            const MyDateString = `${formatedDate.getFullYear()}-${("0" +(formatedDate.getMonth() + 1)).slice(-2)}-${("0" + formatedDate.getDate()).slice(-2)}`;
            this.formData.SessionDate = MyDateString;
          } else  { // Final Session
            this.formData.SessionDate = ""
          }
        } else { // Otherwise uses the Date/Time Picker
          console.log("Before check - ", this.hours, ' - ', this.minutes)
          if (this.hours == null) {
            this.hours = this.currentSessions.SessionTimeISO.split(":")[0];
          }
          if (this.minutes == null) {
            this.minutes = this.currentSessions.SessionTimeISO.split(":")[1];
          }
          console.log("After check - ", this.hours, ' - ', this.minutes)
          this.formData.SessionTime = `${this.hours}:${this.minutes}:00`;
        }
        let formatedDate = new Date(this.currentSessions.SessionDateISO);
         const dTodaysDate = new Date()
         console.log(dTodaysDate)
         console.log(formatedDate)
         if (formatedDate > dTodaysDate) { // Is session for today - Shows error message if not
          this.isSessionErrorMessageVisible = true;
        } else {
          console.log(this.formData.AttendanceID, ' : ', this.formData.ModeID)
          if ((this.formData.SessionDate !== null) && (this.formData.AttendanceID !== null) && (this.formData.ModeID !== null) && (this.formData.SessionTime !== null) 
          ) {
            this.isModalVisible = true;
          } else {
            if ((this.formData.SessionDate == "") && (this.formData.AttendanceID !== null) && (this.formData.SessionTime !== null)) { 
              // Checks if Session Date is "" which is for Final Session - Still needs to pass other null checks - does not need Mode of Next Session as no future session
              this.isModalVisible = true;
            } else {
            this.isErrorMessageVisible = true;
            }
          }
        } 
      }
      //this.isModalFailureVisible = true
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeFailureModal() {
      this.isModalFailureVisible = false;
    },
    closeErrorMessage() {
      this.isErrorMessageVisible = false;
    },
    closeSessionErrorMessage() {
      this.isSessionErrorMessageVisible = false;
    },
    getAttendanceOptions() {
      PFServices.getAttendance().then((res) => {
        this.attendanceOptions = res.GetAttendanceResult;
        if (this.attendanceOptions.length === 1) {
          this.getAttendanceOptions();
        }
      });
    },
    getModeOptions() {
      PFServices.getMode().then((res) => {
        this.modeOptions = res.GetModeResult;
        if (this.modeOptions.length === 1) {
          this.getModeOptions();
        }
      });
    },
  },
  mounted() {
    this.getAttendanceOptions();
    this.getModeOptions();
  },
  computed: {
    getFormatedDate() {
      let formatedDate
      if (this.currentSessions) { 
        formatedDate = this.currentSessions.SessionDateISO.split("-").reverse().join().replaceAll(",", "/");
        const dToday = new Date()
        const dSessionDate = new Date(this.currentSessions.SessionDateISO)
        console.log(dToday, " - ", dSessionDate, " - line 241")
/*         if (dToday < dSessionDate) {
          console.log("line 243")
          const btnSubmit = document.getElementById("submitbutton");
          console.log("line 245")
          btnSubmit.disabled = true;
          console.log("line 247")
        }  */
        return formatedDate;
      }
      return formatedDate;
    },
    getFormatedTime() {
      let formatedTime
      if (this.currentSessions) {
        const formatedTimeHour = this.currentSessions.SessionTimeISO.split(":")[0];
        const formatedTimeMinutes = this.currentSessions.SessionTimeISO.split(":")[1];
        formatedTime = `${formatedTimeHour}:${formatedTimeMinutes}`;
        return formatedTime;
      }
      return formatedTime;
    },
  },
};
</script>

<style scoped>
table,
th {
  margin: auto;
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}

td {
  margin: auto;
  border: 1px solid black;
  border-collapse: collapse;
}

.client-form {
  float: left;
  width: 80%;
}

.attendance {
  padding: 0px;
  width: 100%;
  bottom: 4vh;
  position: relative;
  border: none;
}

.mode {
  padding: 0px;
  width: 100%;
  bottom: 4vh;
  position: relative;
  border: none;
}

.radio-button {
  padding: 0px 0px 0px 0.5vw;
}

.radio-input {
  text-align: left;
  padding: 0px 0px 0px 0.5vw;
}

.calendar-table {
  width: 15vw;
}

.calendar {
  /* width: 98%; */
  border: none;
}

.date-next-session {
  width: 15vw;
}

.submit-button {
  padding: 10vh 7vw 0 0;
}

select {
  border: none;
}

.notes {
  resize: none;
  height: 10vh;
  padding: 0;
  width: 100%;
  margin: 0;
  border: none;
}

button {
  float: right;
  background-color: #d7d1f7;
  border: none;
  color: #000;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  margin: 4px 2px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 16px;
}

button2 {
  float: right;
  background-color: #d7d1f7;
  border: none;
  color: #000;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  margin: 4px 2px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 16px;
}

.client-name {
  padding-right: 10vw;
  text-align: right;
  /* float: right; */
}

.title {
  text-align: left;
}

.notes::-webkit-input-placeholder { /* WebKit browsers */
  font-family: 'myFont', Open Sans, Calibri, sans-serif;
  font-size: 11;
}

.notes:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-family: 'myFont', Open Sans, Calibri, sans-serif;
  font-size: 11;
}

.notes:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-family: 'myFont', Open Sans, Calibri, sans-serif;
  font-size: 11;
}

.notes::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-family: 'myFont', Open Sans, Calibri, sans-serif;
  font-size: 11;
}
    
.notes:focus::-webkit-input-placeholder { /* WebKit browsers */
  opacity:0;
}
    
.notes:focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  opacity:0; 
}

.notes:focus::-moz-placeholder { /* Mozilla Firefox 19+ */
  opacity:0; 
}
    
.notes:focus:-ms-input-placeholder { /* Internet Explorer 10+ */
  opacity:0; 
}

</style>