<template>
  <div>
    <Header class="header-bar" :user="user.Forename" />
    <SideBar class="side-bar" v-on:view-client="viewClient($event)"  :currentClients="currentClients" :previousClients="previousClients"  />
    <ClientForm v-if="isClientFormLoader" class="client-form" :currentSessions="currentSessions[0]" :selectedClient="selectedClient"  />
    <ClientNameDisplay v-if="!isClientFormLoader" :selectedClient="selectedClient" />
    <CurrentClientTable v-if="isLoaded" class="current-client-table" :previousSessions="previousSessions" :selectedClient="selectedClient" /> 
  </div>
</template>

<script>
import ClientForm from "../components/ClientForm.vue"
import SideBar from "../components/SideBar.vue";
import Header from "../components/Header.vue";
import CurrentClientTable from '../components/CurrentClientTable.vue'
import PFServices from  "../services/PFServices.js"
import ClientNameDisplay from '../components/ClientNameDisplay.vue'

export default {
  name: "current-client-page",
  components: {
    ClientForm,
    SideBar,
    Header,
    CurrentClientTable,
    ClientNameDisplay,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('User')),
      selectedClient: JSON.parse(localStorage.getItem('CurrentClient')),
      previousClients: JSON.parse(localStorage.getItem('previousSessions')),
      currentClients:  JSON.parse(localStorage.getItem('CurrentClients')),
      previousSessions: [],
      currentSessions: [],
      isLoaded: false,
      isClientFormLoader: false,
      //sessionID: "",
    }
  },
  methods: {
    viewClient(client) {
//      this.clearListOfSessions();
      this.isLoaded = false;
      localStorage.setItem('CurrentClient', JSON.stringify(client));
      //this.getListOfSessions();
      if (client.CurrentClient !== 0) {
        this.selectedClient = client;
        this.getListOfSessions();
      } else {
        this.getListOfSessions();
        this.$router.push("PreviousClient");
      }
      
    },

    clearListOfSessions() {
      this.previousSessions = [];
      this.currentSessions = [];
      let currentSessions = []
      let previousSessions = []
       
        this.currentSessions = currentSessions;
        this.previousSessions = previousSessions;
        this.loadPage()
    },


    getListOfSessions() {
      this.previousSessions = [];
      this.currentSessions = [];
      let currentSessions = []
      let previousSessions = []
      PFServices.getSessionList(this.selectedClient.ClientID)
      .then(res => {
        for (let session of res.GetSessionListResult) {
          //console.log("Current - ", session.ClientID, " - ", session.SessionID, " - ", session.AttendanceID)
          if (session.AttendanceID === 0) {
            currentSessions.push(session)
            this.currentSessions.push(session)
          } else {
            previousSessions.push(session)
            this.previousSessions.push(session)
          }
        }          
        this.currentSessions = currentSessions;
        this.previousSessions = previousSessions;
        console.log('Client: ', this.selectedClient.ClientID)
        this.loadPage()
      })
    },
    loadPage() {
      if ((this.previousSessions.length > 0) && (this.currentSessions.length > 0) && (this.selectedClient.ClientID === this.currentSessions[0].ClientID)) {
        this.isLoaded = true;
        this.isClientFormLoader = true;
      } else if ((this.previousSessions.length > 0) && (this.currentSessions.length == 0) && (this.selectedClient.ClientID === this.previousSessions[0].ClientID)) {
       this.isLoaded = true;
        this.isClientFormLoader = false;
      } else {
        this.getListOfSessions()
      }
    },
  },
  mounted() {
    this.getListOfSessions();
  }, 
}
</script>

<style scoped>
.client-form {
  margin: auto;
  margin-left: 20vw;
  width: 80vw;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 4vh;
}

.current-client-table {
  margin: auto;
  padding-top: 10vh;
  margin-left: 20vw;
  margin-right: 5vw;
  height: 45vh;
}
</style>