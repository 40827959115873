<template>
  <div class="login-box">
    <Login  />
  </div>
</template>

<script>
import Login from '../components/Login.vue'

export default {
  name: 'login-page',
  components: {
    Login,
  }
}
</script>

<style>

.login-box {
  margin: auto;
  width: 35vw;
  height: 10vh;
  margin: auto;
  padding: 10px;
}

</style>