<template>
  <div>
    <div class="imgcontainer">
      <img src="../assets/HeaderLogo.png" alt="Logo" class="avatar" />
    </div>
    <div class="container">
      <h1>An email has been sent to you to reset your password</h1>
    </div>
      <router-link to="/Login">Return to login page</router-link>

    
  </div>
</template>

<script>
export default {
  name: "information",
};
</script>

<style scoped>
form {
  border: 3px solid #f1f1f1;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.invalid-email {
  color: #ff0000;
}

button:hover {
  opacity: 0.8;
}

.imgcontainer {

  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}


@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
}
</style>