<template>
  <div>
    <Information /> 
  </div>
</template>

<script>
import Information from '../components/Information.vue'

export default {
  name: 'information-page',
  components: {
    Information
  }
}
</script>

<style scoped>

</style>