<template>
  <div>
    <h3 class="title">Previous Sessions</h3>
    <div class="user-table">
      <table>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Attendance</th>
          <th style="width: 0vw">Contribution</th>
          <th style="width: 100vw">Notes</th>
        </tr>

        <tr v-for="session of previousSessions" :key="session.SessionID">
          <td>{{ session.SessionDateISO.split("-").reverse().join().replaceAll(",", "/") }}</td>
          <td>{{ session.SessionTimeISO.split(":")[0] }}:{{ session.SessionTimeISO.split(":")[1] }}</td>
          <td>{{ session.Attendance }}</td>
          <td>{{ session.Contribution }}</td>
          <td>{{ session.Notes }}</td>
        </tr>
      </table>
    </div>

    <div class="statistics">
      <p>Total Sessions Attended</p>
      <h3>{{ getTotalSessionsAttended }}</h3>
      <p>Late Cancellations and DNAs</p>
      <h3>{{ getTotalSessionsNotAttended }}</h3>
      <p>Total Donation</p>
      <h3>£{{ getTotalDonations.toFixed(2) }}</h3>
      <p>Average Donation per Session</p>
      <h3>£{{ getDon.toFixed(2) }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "current-client-form",
  props: ["previousSessions", "selectedClient"],
  computed: {
    getTotalSessionsAttended() {
      let totalSessionsAttended = [];
      // console.log('selectedClient', this.selectedClient.ClientID)
      // console.log('previousSessions', this.previousSessions[0].ClientID)
      if (this.selectedClient.ClientID === this.previousSessions[0].ClientID) {
        for (let session of this.previousSessions) {
          if (session.AttendanceID === 1 || session.AttendanceID === 2) {
            totalSessionsAttended.push(session);
          }
        }
      }
      return totalSessionsAttended.length;
    },

    getTotalSessionsNotAttended() {
      let totalSessionsNotAttended  = [];
      if (this.selectedClient.ClientID === this.previousSessions[0].ClientID) {
        for (let session of this.previousSessions) {
          if (session.AttendanceID !== 2 && session.AttendanceID !== 1 && session.AttendanceID !== 3 && session.AttendanceID !== 4) {
            totalSessionsNotAttended.push(session);
          }
        }
      }
      return totalSessionsNotAttended.length;
    },

    getTotalDonations() {
      let total = 0;
      if (this.selectedClient.ClientID === this.previousSessions[0].ClientID) {
        this.previousSessions.forEach(function (value) {
          total += value.Contribution;
        });
      }
      return total;
    },

    getDon() {
      let avgDonations = 0;
      if (this.selectedClient.ClientID === this.previousSessions[0].ClientID) {
        avgDonations = this.getTotalDonations / this.previousSessions.length;
        if (isNaN(avgDonations)) {
          avgDonations = 0;
        }
      }
      return avgDonations;
    },
  },
};
</script>
<style scoped>
table,
th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}

.title {
  float: left;
  margin-right: 20vw;
}

th {
  width: 30vw;
}

.user-table {
  float: left;
  height: 50vh;
  overflow-y: scroll;
  margin-right: 20vw;
  border: 1px solid black;
}

td {
  height: 10vh;
  border: 1px solid black;
}

.statistics {
  text-align: left;
  top: 55vh;
  left: 80%;
  position: absolute;
}

h3 {
  text-align: left;
  padding-bottom: 1vh;
}
</style>
