<template>
  <div>
    <h3 class="client-name">
      {{ selectedClient.Forename }} {{ selectedClient.Surname.charAt(0) }} - {{ selectedClient.ClientRef }}
    </h3>
    <br>
    <div class="user-table">
      <table>
        <tr>
          <th style="width: 8vw">Date</th>
          <th>Time</th>
          <th>Attendance</th>
          <th style="width: 10vw">Contribution</th>
          <th style="width: 60vw">Notes</th>
        </tr>

        <tr v-for="session of previousSessions" :key="session.SessionID">
          <td>{{ session.SessionDateISO.split("-").reverse().join().replaceAll(",", "/") }}</td>
          <td>{{ session.SessionTimeISO.split(":")[0] }}:{{ session.SessionTimeISO.split(":")[1]}}</td>
          <td>{{ session.Attendance }}</td>
          <td>{{ session.Contribution }}</td>
          <td>{{ session.Notes }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "previous-client-page",
  props: ['selectedClient', 'previousSessions'],
};
</script>

<style>
table,
th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}

.client-name {
  text-align: left;
  font-weight: normal;
}

.user-table {
  float: left;
  height: 75vh;
  overflow-y: scroll;
  margin-right: 5vw;
  border: 1px solid black;
}

td {
  height: 10vh;
  border: 1px solid black;
}
</style>