const baseURL = "https://api.worx24.co.uk/Worx24API/PFCounsellingService.svc";

export default {
  getLogin(login) {
    const payload = {
      EmailAddress: login.Email,
      OrganisationID: 31,
      Password: login.Password,
      Apptoken: 123456
    }
    return fetch(`${baseURL}/ValidateMemberLogin`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
     .then(res => res.json());
  },

  getNewPassword(email) {
    const payload = {
      EmailAddress: email,
    }
    return fetch(`${baseURL}/ForgottenPassword`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(res => res.json());
  },

  getAttendance() {
    const payload = {
      AppToken: 123456,
      OrganisationID: 31
    }
    return fetch(`${baseURL}/GetAttendance`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(res => res.json());
  },

  getMode() {
    const payload = {
      AppToken: 123456,
      OrganisationID: 31
    }
    return fetch(`${baseURL}/GetMode`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(res => res.json());
  },

  getClientsList(CounsellorID) {
    if (CounsellorID !== undefined) {
      const payload = {
        AppToken: "123456",
        OrganisationID: 31,
        CounsellorID: CounsellorID,
      }
      return fetch(`${baseURL}/GetClientList`, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
      .then(res => res.json());
    }
  },

  getSessionList(clientID) {
    const payload = {
      AppToken: 123456,
      OrganisationID: 31,
      ClientID: clientID,
    }
    return fetch(`${baseURL}/GetSessionList`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(res => res.json());
  },

  postSession(payload) {
    return fetch(`${baseURL}/SetSessionDetailsV1`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(res => res.json());
  }
}