import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import HomeSupervisor from '../views/HomeSupervisor.vue';
import LoginPage from '../views/LoginPage.vue';
import CurrentClientPage from '../views/CurrentClientPage.vue';
import PreviousClientPage from '../views/PreviousClientPage.vue';
import RecoverPasswordPage from '../views/RecoverPasswordPage.vue';
import InformationPage from '../views/InformationPage.vue';

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/HomeSupervisor',
    name: 'HomeSupervisor',
    component: HomeSupervisor
  },
  {
    path: '/Login',
    name: 'LoginPage',
    alias: ['/'],
    component: LoginPage
  },
  {
    path: '/CurrentClient',
    name: 'ClientFormPage',
    component: CurrentClientPage
  },
  {
    path: '/PreviousClient',
    name: 'PreviousClientPage',
    component: PreviousClientPage
  },
  {
    path: '/RecoverPassword',
    name: 'RecoverPasswordPage',
    component: RecoverPasswordPage
  },
  {
    path: '/Info',
    name: 'InformationPage',
    component: InformationPage
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
