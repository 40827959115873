<template>
  <div>
    <Header  class="header-bar" :user="user.Forename"/>
    <SideBar class="side-bar" :currentClients="currentClients" :previousClients="previousClients" v-on:view-client="viewClient($event)"/>
    <PreviousClientTable v-if="isLoaded" class="previous-table" :selectedClient="selectedClient" :previousSessions="previousSessions" />
  </div>
</template>

<script>
import PreviousClientTable from "../components/PreviousClientTable.vue";
import SideBar from "../components/SideBar.vue";
import Header from "../components/Header.vue";
import PFServices from  "../services/PFServices.js"

export default {
  name: "previous-client-page",
  components: {
    PreviousClientTable,
    SideBar,
    Header,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('User')),
      previousClients: JSON.parse(localStorage.getItem('previousSessions')),
      currentClients:  JSON.parse(localStorage.getItem('CurrentClients')),
      selectedClient: JSON.parse(localStorage.getItem('CurrentClient')),
      previousSessions: [],
      currentSessions: [],
      isLoaded: false,
    };
  },
    methods: {
    viewClient(client) {
      this.getListOfSessions();
        localStorage.setItem('CurrentClient', JSON.stringify(client));
      if (client.CurrentClient === 0) {
        localStorage.setItem('PreviousClient', JSON.stringify(client));
        this.selectedClient = client;
      } else {
        this.selectedClient = client;
        this.$router.push("CurrentClient");
      }
    },
    getListOfSessions() {
      this.previousSessions = [],
      this.currentSessions = [],
      // console.log(this.selectedClient.ClientID);
      PFServices.getSessionList(this.selectedClient.ClientID)
      .then(res => {
        for (let session of res.GetSessionListResult) {
          //console.log("Previous - ", session.ClientID, " - ", session.SessionID, " - ", session.AttendanceID)
          if (session.AttendanceID !== 0) {
            this.previousSessions.push(session)
          } else {
            this.currentSessions.push(session)
          }
        }
        this.loadPage()
      })
    },

    loadPage() {
      if ((this.previousSessions.length > 0) && (this.selectedClient.ClientID === this.previousSessions[0].ClientID)) {
        this.isLoaded = true;
      } else {
        this.getListOfSessions();
      }
    }
  },
  mounted() {
    this.getListOfSessions();

  }, 
};
</script>

<style scoped>
.previous-table {
  padding-top: 5vh;
  margin-left: 20vw;
}
</style>