<template>
    <div class="login-box">
      <ResetPassword />
    </div>
</template>

<script>
import ResetPassword from '../components/ResetPassword.vue'

export default {
  name: "recover-password-page",
  components: {
    ResetPassword,
  },
  data() {
    return {};
  },
}
</script>

<style scoped>

.login-box {
  margin: auto;
  width: 35vw;
  height: 10vh;
  margin: auto;
  padding: 10px;
}

</style>